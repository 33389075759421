<template>
  <div>
    <figure class="flex h-96 flex-auto flex-col justify-between bg-gray-900">
      <blockquote class="prose text-lg text-white">
        <p>{{ review.review }}</p>
      </blockquote>
      <figcaption class="mt-10 flex items-center gap-x-6">
        <div class="text-base">
          <div class="font-semibold text-white">{{ review.name }}</div>
          <div class="text-sm text-gray-400">{{ review.title }}</div>
        </div>
      </figcaption>
    </figure>
  </div>
</template>

<script setup>
const props = defineProps({
  review: {
    type: Object,
    default: () => {},
  },
});

const srcSet = computed(() => {
  const photo = props.review.photo;
  return `${photo["2x"].url} 2x, ${photo["3x"].url} 3x, ${photo["4x"].url} 4x`;
});
</script>
